"use client";

import dynamic from "next/dynamic";
import { FC } from "react";
import { IPageBlock } from "../../../types/ui";

const CustomizableShopSection = dynamic(() => import("."));

const CustomizableShopSectionLoader: FC<IPageBlock> = (props) => <CustomizableShopSection {...props} />;

export default CustomizableShopSectionLoader;
