"use client";

import dynamic from "next/dynamic";
import { FC } from "react";
import { IPageBlock } from "../../../types/ui";

const ShopSection = dynamic(() => import("."));

const ShopSectionLoader: FC<IPageBlock> = (props) => <ShopSection {...props} />;

export default ShopSectionLoader;
