"use client";

import dynamic from "next/dynamic";
import { FC } from "react";
import { IPageBlock } from "../../../types/ui";

const ModelsBlockVertical = dynamic(() => import("."));

const ModelsBlockVerticalLoader: FC<IPageBlock> = (props) => <ModelsBlockVertical {...props} />;

export default ModelsBlockVerticalLoader;
