const PhoneIcon = () => (
  <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.62097 2.93835L6.75135 2.83182C7.12487 2.52664 7.67507 2.58204 7.98026 2.95556L10.076 5.52059C10.4254 5.94828 10.362 6.57826 9.93428 6.92769L8.83107 7.82906C8.75403 7.892 8.72826 7.9985 8.76799 8.0897C9.77937 10.4111 11.4114 12.4086 13.4846 13.8625C13.566 13.9196 13.6755 13.9156 13.7526 13.8527L14.8558 12.9513C15.2835 12.6019 15.9135 12.6653 16.2629 13.093L18.3587 15.6581C18.6638 16.0316 18.6084 16.5817 18.2349 16.8869L18.1069 16.9915C16.2412 18.5158 13.5565 18.5053 11.7019 16.9676C9.10882 14.8178 6.95658 12.1837 5.36722 9.21388C4.23128 7.09128 4.75666 4.46155 6.62097 2.93835Z"
      fill="currentColor"
    />
  </svg>
);

export default PhoneIcon;
