"use client";
import { useCallback, FC } from "react";
import styles from "./text-banner.module.scss";
import { ButtonAction } from "../../../types/site";
import { IPageBlock } from "../../../types/ui";
import { handleButtonAction } from "../../../utils/ui";
import Button from "../../common-components/button";
import { useIsMobile } from "../../../utils/useIsMobile";
import { getButtonActionResult, sendAnalyticsEvent } from "../../../utils/analitics";

interface ITextBanner {
  title: string;
  text: string;
  button: ButtonAction;
  linkPath: string;
  linkTitle: string;
}

const TextBanner: FC<IPageBlock> = ({ pageContext, config }) => {
  const isMobile = useIsMobile(768, pageContext.isSmartphone);
  const blockConfig: ITextBanner = config;

  const handleMenuButton = useCallback((button: ButtonAction) => {
    handleButtonAction(button);
    sendAnalyticsEvent("asc_cta_interaction", {
      element_text: button.title,
      element_type: "header",
      event_action_result: getButtonActionResult(button),
      link_url: button.path,
      element_title: button.title,
    });
  }, []);
  return !isMobile ? (
    <section className={styles.container}>
      <div className={styles.content}>
        <div className={styles.info}>
          {blockConfig?.title && <h4 className={styles.title}>{blockConfig?.title}</h4>}
          {blockConfig?.text && <p className={styles.text}>{blockConfig?.text}</p>}
        </div>
        <div className={styles.actions}>
          {blockConfig?.button && (
            <Button
              name={"textBannerButton"}
              ariaLabel={blockConfig?.button.title}
              type="primary"
              text={blockConfig?.button.title}
              className={styles.button}
              onClick={() => handleMenuButton(blockConfig?.button!)}
            />
          )}
          {blockConfig?.linkPath && (
            <a href={blockConfig?.linkPath} title={blockConfig?.linkTitle} className={styles.link}>
              {blockConfig?.linkTitle}
            </a>
          )}
        </div>
      </div>
    </section>
  ) : (
    <></>
  );
};

export default TextBanner;
