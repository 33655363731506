import { FC } from "react";
import cx from "classnames";
import styles from "./add-to-wallet-button.module.scss";

export enum WalletType {
  APPLE = "APPLE",
  GOOGLE = "GOOGLE",
}

interface IAddToWalletButton {
  className?: string;
  onClick: () => void;
  type: WalletType;
}

const AddToWalletButton: FC<IAddToWalletButton> = ({ className, onClick, type }) => {
  const image = type === WalletType.APPLE ? "apple-wallet-icon.svg" : "google-wallet-icon.svg";

  return (
    <button className={cx(styles.button, className)} onClick={onClick}>
      <img src={`/images/${image}`} alt="" />
    </button>
  );
};

export default AddToWalletButton;
