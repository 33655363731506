import { useCallback, FC } from "react";
import cx from "classnames";
import Button from "../../common-components/button";
import styles from "./card.module.scss";
import { ISpecialCardApi } from "../../../types/service";
import { handleButtonAction } from "../../../utils/ui";
import { ButtonAction, ClientSiteData } from "../../../types/site";
import { formatDate } from "../../../utils/stringFormatter";
import BlockImage from "../../common-components/block-image";
import AddToWalletButton from "../../common-components/add-to-wallet-button/add-to-wallet-button";
import { WalletType } from "../../common-components/add-to-wallet-button/add-to-wallet-button";
import { createAppleWalletSpecials /*, createGoogleWalletSpecials */ } from "../../../api/client-api";
import { getButtonActionResult, sendAnalyticsEvent } from "../../../utils/analitics";
import { Translations } from "../../../utils/translations";
import { getTranslationModule } from "../../../utils/translations";

export interface ISpecialCardNew {
  siteData: ClientSiteData;
  data: ISpecialCardApi;
  className?: string;
  onClick?: () => void;
  popup?: boolean;
  dealerPhoto?: string;
  baseUrl: string;
  translations: Translations;
}

function getSpecialsValue(data: ISpecialCardApi): string {
  if (data.discountDescription) {
    return `${data.discountValue} ${data.discountDescription}`;
  }
  return data.discountValue || "";
}

function getSpecialsDescription(data: ISpecialCardApi): string {
  if (data.subtitle) {
    return `${data.subtitle} ${data.title}`;
  }

  return data.title || "";
}

const SpecialCard: FC<ISpecialCardNew> = ({ siteData, data, className, onClick, popup, dealerPhoto, translations }) => {
  const t = getTranslationModule(translations, "common");

  const handleServiceButton = useCallback(
    (button: ButtonAction) => {
      handleButtonAction(button, {
        name: data?.title + " " + data?.subtitle,
        image: data?.image,
      });
      sendAnalyticsEvent("asc_cta_interaction", {
        element_text: button.title,
        element_type: "body",
        event_action_result: getButtonActionResult(button),
        link_url: button.path,
        element_title: button.title,
      });
    },
    [data?.title, data?.subtitle, data?.image],
  );

  const handleAppleWalletClick = useCallback(() => {
    createAppleWalletSpecials(siteData.id, {
      specialValue: getSpecialsValue(data),
      specialDescription: getSpecialsDescription(data),
      expires: data.expirationDate,
      description: data.description,
      shortDescription: data.description,
      terms: data.terms,
    }).then((res) => {
      const file = new File([res], "specials.pkpass", { type: "application/vnd.apple.pkpass" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(file);
      link.download = file.name;
      link.click();
    });
  }, [data, siteData.id]);

  /*const handleGoogleWalletButtonClick = useCallback(() => {
    const theme = getTheme(siteData.theme);
    createGoogleWalletSpecials(siteData.id, {
      specialValue: getSpecialsValue(data),
      specialDescription: getSpecialsDescription(data),
      expires: data.expirationDate,
      description: data.description,
      shortDescription: data.description,
      terms: data.terms,
    }).then((res) => window.open(res, "_blank"));
  }, [data, siteData.id]);*/

  return (
    <div
      className={cx(styles.container, className, {
        [styles.popup]: popup,
      })}
    >
      {data?.expirationDate && (
        <div className={styles.expirationContainer}>
          <span className={styles.expirationDate}>{t("expires") + " " + formatDate(data?.expirationDate)}</span>
        </div>
      )}
      <div className={styles.imageContainer}>
        {data?.image && (
          <BlockImage
            image={data?.image}
            siteData={siteData}
            alt={data?.title + " " + data?.subtitle}
            lazyLoading={false}
            mobile={false}
            className={styles.image}
          />
        )}
        {!data?.image && dealerPhoto && <img className={styles.dealerPhoto} src={dealerPhoto} alt="dealer Photo" />}
      </div>
      <div className={styles.content}>
        <div className={styles.header}>
          <div className={styles.headerContainer}>
            <h3 className={styles.subtitle}>{data?.subtitle}</h3>
            <h3 className={styles.title}>{data?.title}</h3>
          </div>
          <div className={styles.discountContainer}>
            <span className={styles.discountValue}>{data?.discountValue}</span>
            <span className={styles.discountDescription}>{data?.discountDescription}</span>
          </div>
        </div>
        {data?.description && <p className={styles.description}>{data?.description}</p>}
        {!popup && (
          <button className={styles.viewDetails} onClick={onClick}>
            {t("view_details")}
          </button>
        )}
        <div className={styles.border}></div>
        <div className={styles.footer}>
          <div className={styles.buttonContainer}>
            {data?.button && (
              <Button
                name={data?.button.title}
                ariaLabel={data?.button.title}
                className={styles.button}
                type={"primary"}
                text={data?.button.title}
                onClick={() => handleServiceButton(data?.button!)}
              />
            )}
            {data?.secondaryButton && (
              <Button
                name={data?.secondaryButton.title}
                ariaLabel={data?.secondaryButton.title}
                className={styles.button}
                type={"secondary"}
                text={data?.secondaryButton.title}
                onClick={() => handleServiceButton(data?.secondaryButton!)}
              />
            )}
          </div>
          <div className={styles.addToWallet}>
            {data.showAppleWallet && (
              <AddToWalletButton
                className={styles.addToWalletButton}
                onClick={handleAppleWalletClick}
                type={WalletType.APPLE}
              />
            )}
            {/*<AddToWalletButton
              className={styles.addToWalletButton}
              onClick={handleGoogleWalletButtonClick}
              type={WalletType.GOOGLE}
            />*/}
          </div>
          <p className={styles.terms}>{data?.terms || ""}</p>
        </div>
      </div>
    </div>
  );
};

export default SpecialCard;
