"use client";

import dynamic from "next/dynamic";
import { FC } from "react";
import { IPageBlock } from "../../../types/ui";

const FinanceApplyForm = dynamic(() => import("./finance-apply-form"));

const FinanceApplyFormLoader: FC<IPageBlock> = (props) => <FinanceApplyForm {...props} />;

export default FinanceApplyFormLoader;
