"use client";

import dynamic from "next/dynamic";
import { FC } from "react";
import { IPageBlock } from "../../../types/ui";

const SearchCarSectionExtended = dynamic(() => import("."));

const SearchCarSectionExtendedLoader: FC<IPageBlock> = (props) => <SearchCarSectionExtended {...props} />;

export default SearchCarSectionExtendedLoader;
