import { SearchFilter } from "../types/filters";

export const FILTER_PROPERTY_TYPE_ENUM = "enum";
export const FILTER_PROPERTY_TYPE_RANGE = "range";

export enum FilterView {
  COLUMN = "column",
  ROW = "row",
}

export class FilterProperty {
  type: string;
  title: string;
  propertyName: string;
  constructor(type: string, propertyName: string, title: string) {
    this.type = type;
    this.title = title;
    this.propertyName = propertyName;
  }

  reset() {
    throw new Error("must be imeplemented");
  }

  // eslint-disable-next-line no-unused-vars
  setValue(_newValue: string) {
    throw new Error("must be imeplemented");
  }

  getValue(): string | undefined {
    throw new Error("must be imeplemented");
  }

  hasValues(): boolean {
    throw new Error("must be imeplemented");
  }

  getFilterSettingValue(): string {
    throw new Error("must be imeplemented");
  }

  createEmpty(): FilterProperty {
    throw new Error("must be imeplemented");
  }
}

export class PossibleFilterValue {
  value: string;
  text: string;
  count: number;
  color?: string;
  selected: boolean = false;

  constructor(value: string, text: string, count: number) {
    this.value = value;
    this.text = text;
    this.count = count;
  }
}

export class EnumerationFilterProperty extends FilterProperty {
  possibleValues: PossibleFilterValue[] = [];
  constructor(propertyName: string, title: string, possibleValues: PossibleFilterValue[]) {
    super(FILTER_PROPERTY_TYPE_ENUM, propertyName, title);
    this.possibleValues = possibleValues;
  }

  reset() {
    this.possibleValues.forEach((item) => {
      item.selected = false;
    });
  }

  setValue(value: string) {
    const selectedValues = new Set(value.split(",").map((item) => this.decodeValue(item)));
    this.possibleValues.forEach((item) => {
      item.selected = selectedValues.has(item.value);
    });
  }

  getValue(): string | undefined {
    const selectedValues = this.possibleValues
      .filter((item) => item.selected)
      .map((item) => this.encodeValue(item.value));

    if (selectedValues.length > 0) {
      return selectedValues.join(",");
    }
  }

  hasValues(): boolean {
    return this.possibleValues.some((item) => item.selected);
  }

  getFilterSettingValue(): string {
    const values = this.possibleValues.filter((item) => item.selected).map((item) => item.text);
    return values.join(",");
  }

  createEmpty(): FilterProperty {
    return new EnumerationFilterProperty(this.propertyName, this.title, []);
  }

  private encodeValue(value: string): string {
    return value.replaceAll(",", "%2C");
  }

  private decodeValue(value: string): string {
    return value.replaceAll("%2C", ",");
  }
}

export class RangeFilterProperty extends FilterProperty {
  minValue: number;
  maxValue: number;
  currentMinValue: number;
  currentMaxValue: number;

  constructor(propertyName: string, title: string, minValue: number, maxValue: number) {
    super(FILTER_PROPERTY_TYPE_RANGE, propertyName, title);
    this.minValue = this.currentMinValue = minValue;
    this.maxValue = this.currentMaxValue = maxValue;
  }

  reset() {
    this.currentMinValue = this.minValue;
    this.currentMaxValue = this.maxValue;
  }

  setValue(value: string) {
    const parts = value.split("-");
    if (parts.length === 1) {
      const rangeValue = parseInt(value);
      this.currentMinValue = this.currentMaxValue = rangeValue;
    } else {
      this.currentMinValue = parseInt(parts[0]);
      this.currentMaxValue = parseInt(parts[1]);
    }
  }

  getValue(): string | undefined {
    if (this.hasValues()) {
      if (this.currentMinValue === this.currentMaxValue) {
        return this.currentMinValue.toString();
      }

      return `${this.currentMinValue}-${this.currentMaxValue}`;
    }
  }

  hasValues(): boolean {
    return this.currentMinValue !== this.minValue || this.currentMaxValue !== this.maxValue;
  }

  getFilterSettingValue(): string {
    if (this.currentMinValue !== this.minValue && this.currentMaxValue !== this.maxValue) {
      return `${this.currentMinValue}-${this.currentMaxValue}`;
    } else if (this.currentMinValue === this.minValue) {
      return `<=${this.currentMaxValue}`;
    } else {
      return `>=${this.currentMinValue}`;
    }
  }

  createEmpty(): FilterProperty {
    return new RangeFilterProperty(this.propertyName, this.title, 0, 0);
  }
}

export interface SortData {
  sortBy?: string;
  order?: string;
}

export class VehicleFilters {
  sortData: SortData = {};
  constFilterData: any = {};
  filterProperties: FilterProperty[] = [];
  changedFilterOrder: string[] = [];

  constructor(filterProperties: SearchFilter[]) {
    if (filterProperties) {
      this.filterProperties = filterProperties.map((filterProperty) => {
        if (filterProperty.values) {
          const possibleValues = filterProperty.values.map((item) => {
            const possibleValue = new PossibleFilterValue(
              this.getFilterPropertyValue(item.value),
              this.getFilterPropertyText(item.value),
              item.possibleCount,
            );
            if (filterProperty.name?.value === "color") {
              possibleValue.color = this.getFilterPropertyColor(item.value.color);
            }

            return possibleValue;
          });
          return new EnumerationFilterProperty(filterProperty.name.value, filterProperty.name.title, possibleValues);
        } else if (filterProperty.range) {
          return new RangeFilterProperty(
            filterProperty.name.value,
            filterProperty.name.title,
            filterProperty.range.minValue,
            filterProperty.range.maxValue,
          );
        } else {
          throw new Error(`unknown filter ${JSON.stringify(filterProperty)}`);
        }
      });
    }
  }

  updateWithQuery(query: any) {
    const { sortBy, order, ...filterValue } = query;
    this.sortData = { sortBy, order };

    const filterPropertiesNames = new Set<string>();
    this.filterProperties.forEach((item) => {
      if (filterValue[item.propertyName]) {
        item.setValue(filterValue[item.propertyName]);
      } else {
        item.reset();
      }
      filterPropertiesNames.add(item.propertyName);
    });

    const allFilterValuesProperties = Object.keys(filterValue);
    allFilterValuesProperties.forEach((property) => {
      if (!filterPropertiesNames.has(property)) {
        this.constFilterData[property] = filterValue[property];
      } else {
        this.changedFilterOrder.push(property);
      }
    });
  }

  addChangedProperty(propertyName: string) {
    if (!this.changedFilterOrder.includes(propertyName)) {
      this.changedFilterOrder.push(propertyName);
    }
  }

  removeChangedProperty(propertyName: string) {
    const propertyIndex = this.changedFilterOrder.indexOf(propertyName);
    if (propertyIndex !== -1) {
      this.changedFilterOrder.splice(propertyIndex, 1);
    }
  }

  setQueryValue(property: string, value: string) {
    this.constFilterData[property] = value;
  }

  deleteQueryValue(property: string) {
    delete this.constFilterData[property];
  }

  getQuery(): any {
    return this.getQueryFromFilters(this.constFilterData);
  }

  getQueryWithoutConstatnts(): any {
    return this.getQueryFromFilters({});
  }

  setSortValue(value: SortData) {
    this.sortData = value;
  }

  resetFilters() {
    this.filterProperties.forEach((item) => {
      item.reset();
    });
    this.changedFilterOrder = [];
  }

  resetFilterProperty(filterProperty: FilterProperty) {
    filterProperty.reset();
    this.removeChangedProperty(filterProperty.propertyName);
  }

  getFilterSettings() {
    return this.filterProperties
      .filter((property) => property.hasValues())
      .map((property) => {
        return {
          name: property.title,
          value: property.getFilterSettingValue(),
          filterProperty: property,
        };
      });
  }

  isEmpty(): boolean {
    return this.filterProperties.length === 0;
  }
  // private methods

  private getFilterPropertyText(filterValue: any) {
    return typeof filterValue === "object" ? filterValue.title : filterValue;
  }

  private getFilterPropertyValue(filterValue: any) {
    return (typeof filterValue === "object" ? filterValue.value : filterValue) || "";
  }

  private getFilterPropertyColor(filterColor: string) {
    if (filterColor && filterColor.charAt(0) === "#") {
      return filterColor;
    }
  }

  private getQueryFromFilters(source: any) {
    const result: any = { ...source };
    const changedProperties = this.changedFilterOrder.map((property) =>
      this.filterProperties.find((item) => item.propertyName === property),
    );
    changedProperties.forEach((item) => {
      const filterValue = item?.getValue();
      if (filterValue) {
        result[item!.propertyName] = filterValue;
      }
    });

    if (this.sortData.sortBy) {
      result.sortBy = this.sortData.sortBy;
    }

    if (this.sortData.order) {
      result.order = this.sortData.order;
    }

    return result;
  }
}
