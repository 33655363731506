import { FC } from "react";

interface IStringToHtmlTransformer {
  title?: string;
  layout: string;
  className?: string;
}

const StringToHtmlTransformer: FC<IStringToHtmlTransformer> = ({ title, layout, className }) => {
  return (
    <section
      className={className}
      dangerouslySetInnerHTML={{ __html: title ? `<h1>${title}</h1>` + layout : layout }}
    ></section>
  );
};

export default StringToHtmlTransformer;
