"use client";

import dynamic from "next/dynamic";
import { FC } from "react";
import { IPageBlock } from "../../../types/ui";

const ScheduleServiceForm = dynamic(() => import("./schedule-service-form"));

const ScheduleServiceFormLoader: FC<IPageBlock> = (props) => <ScheduleServiceForm {...props} />;

export default ScheduleServiceFormLoader;
