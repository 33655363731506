import { FC } from "react";
import styles from "./popup.module.scss";
import Modal from "../../common-components/modal";
import { ISpecialCardApi } from "../../../types/service";
import SpecialCard from "./card";
import { useIsMobile } from "../../../utils/useIsMobile";
import CloseIcon from "../../icons/close-icon";
import { ClientSiteData } from "../../../types/site";
import { PageContext } from "../../../types/ui";
import { Translations } from "../../../utils/translations";

interface ISpecialCardNewPopup {
  isOpen: boolean;
  handleClose: (e: React.UIEvent) => void;
  data: ISpecialCardApi;
  isMobile: boolean;
  baseUrl: string;
  siteData: ClientSiteData;
  pageContext: PageContext;
  translations: Translations;
}

const SpecialCardNewPopup: FC<ISpecialCardNewPopup> = ({
  isOpen,
  handleClose,
  data,
  isMobile,
  baseUrl,
  siteData,
  translations,
}) => {
  const isTablet = useIsMobile(768, isMobile);

  const dealerPhoto = !siteData?.noImagesPhoto ? "" : siteData?.baseUrl + siteData?.noImagesPhoto;

  const modalStyles = {
    overlay: {
      backgroundColor: "rgba(24, 24, 24, 0.8)",
    },
    content: {
      maxWidth: "580px",
      width: isTablet ? "calc(100% - 30px)" : "100%",
    },
  };

  return (
    <Modal isOpen={isOpen} onClose={(e) => handleClose(e)} style={modalStyles} hideCloseButton>
      <div className={styles.container}>
        <SpecialCard
          data={data}
          className={styles.card}
          baseUrl={baseUrl}
          popup={true}
          dealerPhoto={dealerPhoto}
          siteData={siteData}
          translations={translations}
        />
        <button className={styles.closeButton} onClick={(e) => handleClose(e)}>
          <CloseIcon />
        </button>
      </div>
    </Modal>
  );
};

export default SpecialCardNewPopup;
