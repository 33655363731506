"use client";

import dynamic from "next/dynamic";
import { FC } from "react";
import { IPageBlock } from "../../../types/ui";

const DealersMap = dynamic(() => import("./dealers-map"));

const DealersMapLoader: FC<IPageBlock> = (props) => <DealersMap {...props} />;

export default DealersMapLoader;
