"use client";

import dynamic from "next/dynamic";
import { FC } from "react";
import { IPageBlock } from "../../../types/ui";

const LeadPopup = dynamic(() => import("./lead-popup"));

const LeadPopupLoader: FC<IPageBlock> = (props) => <LeadPopup {...props} />;

export default LeadPopupLoader;
