"use client";

import dynamic from "next/dynamic";
import { FC } from "react";
import { IPageBlock } from "../../../types/ui";

const HomeBannerUpdated = dynamic(() => import("./home-banner-updated"));

const HomeBannerUpdatedLoader: FC<IPageBlock> = (props) => <HomeBannerUpdated {...props} />;

export default HomeBannerUpdatedLoader;
