const MinusIcon = ({ className }) => (
  <svg className={className} width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_10047_19196)">
      <path fillRule="evenodd" clipRule="evenodd" d="M0 4L10 4L10 6L-8.74228e-08 6L0 4Z" fill="currentColor" />
    </g>
    <defs>
      <clipPath id="clip0_10047_19196">
        <rect width="10" height="10" fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
);

export default MinusIcon;
