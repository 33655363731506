"use client";

import dynamic from "next/dynamic";
import { FC } from "react";
import { IPageBlock } from "../../../types/ui";

const ScheduleTestDriveForm = dynamic(() => import("./schedule-test-drive-form"));

const ScheduleTestDriveFormLoader: FC<IPageBlock> = (props) => <ScheduleTestDriveForm {...props} />;

export default ScheduleTestDriveFormLoader;
