"use client";

import { FC, useEffect } from "react";
import {
  AnalyticsPageType,
  createAnalyticsDataFromVehicle,
  fillAscDataLayer,
  sendAnalyticsEvent,
} from "../../../utils/analitics";
import { ModelCount } from "../../../types/ModelInfo";
import { Vehicle } from "../../../types/vehicle";

interface IPageAnalytics {
  analyticsPageType: AnalyticsPageType;
  locale: string;
  newModels: ModelCount[];
  createAscDataLayer?: boolean;
  vehicles?: Vehicle[];
  vehicle?: Vehicle;
  dealerName: string;
}

const PageAnalytics: FC<IPageAnalytics> = ({
  newModels,
  createAscDataLayer,
  vehicles,
  dealerName,
  analyticsPageType,
  locale,
  vehicle,
}) => {
  useEffect(() => {
    const dataLayerVehicles = vehicles || (vehicle ? [vehicle] : []);
    fillAscDataLayer(newModels, dataLayerVehicles, analyticsPageType, locale, dealerName, createAscDataLayer);
    if (!vehicle) {
      sendAnalyticsEvent("asc_pageview", { page_type: analyticsPageType, item_results: dataLayerVehicles.length });
    } else {
      const vehcileData = createAnalyticsDataFromVehicle(vehicle);
      sendAnalyticsEvent("asc_pageview", {
        page_type: analyticsPageType,
        item_results: dataLayerVehicles.length,
        ...vehcileData,
      });
    }
  }, [createAscDataLayer, dealerName, newModels, analyticsPageType, locale, vehicles, vehicle]);
  return null;
};

export default PageAnalytics;
