"use client";

import dynamic from "next/dynamic";
import { FC } from "react";
import { IPageBlock } from "../../../types/ui";

const VehicleSlider = dynamic(() => import("."));

const VehicleSliderLoader: FC<IPageBlock> = (props) => <VehicleSlider {...props} />;

export default VehicleSliderLoader;
