"use client";

import dynamic from "next/dynamic";
import { FC } from "react";
import { IPageBlock } from "../../../types/ui";

const Staff = dynamic(() => import("."));

const StaffLoader: FC<IPageBlock> = (props) => <Staff {...props} />;

export default StaffLoader;
