"use client";

import dynamic from "next/dynamic";
import { FC } from "react";
import { IVehicleContainer } from ".";

const VehicleContainer = dynamic(() => import("."));

const VehicleContainerLoader: FC<IVehicleContainer> = (props) => <VehicleContainer {...props} />;

export default VehicleContainerLoader;
