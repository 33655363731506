import { useCallback, FC } from "react";
import cx from "classnames";
import Button from "../../common-components/button";
import BlockImage from "../../common-components/block-image";
import styles from "./service-card.module.scss";
import { IServiceCard } from "../../../types/service";
import { handleButtonAction } from "../../../utils/ui";
import { ButtonAction } from "../../../types/site";
import ArrowIcon from "../../icons/arrow-icon";
import { getButtonActionResult, sendAnalyticsEvent } from "../../../utils/analitics";

const ServiceCard: FC<IServiceCard> = ({ image, title, text, className, link, button, siteData, isMobile }) => {
  const handleServiceButton = useCallback(
    (buttonAction: ButtonAction) => {
      handleButtonAction(buttonAction, { name: title, image });
      sendAnalyticsEvent("asc_cta_interaction", {
        element_text: buttonAction.title,
        element_title: buttonAction.title,
        element_type: "body",
        event_action_result: getButtonActionResult(buttonAction),
        link_url: buttonAction.path,
      });
    },
    [image, title],
  );

  return (
    <div className={cx(styles.container, className)}>
      <div className={styles.image}>
        {image && <BlockImage image={image} alt={"service"} siteData={siteData} mobile={isMobile} />}
      </div>
      <div className={styles.content}>
        <h4 className={styles.title}>{title}</h4>
        <p className={styles.text}>{text}</p>
        {link && (
          <a href={link.path} className={styles.readMore}>
            {link.title}
            <ArrowIcon />
          </a>
        )}
        {button && (
          <Button
            name={button.title}
            ariaLabel={button.title}
            className={styles.button}
            type={"secondary"}
            text={button.title}
            onClick={() => handleServiceButton(button!)}
          />
        )}
      </div>
    </div>
  );
};

export default ServiceCard;
