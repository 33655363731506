"use client";

import dynamic from "next/dynamic";
import { FC } from "react";
import { IPageBlock } from "../../../types/ui";

const Testimonials = dynamic(() => import("./testimonials"));

const TestimonialsLoader: FC<IPageBlock> = (props) => <Testimonials {...props} />;

export default TestimonialsLoader;
