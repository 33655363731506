import { FC } from "react";
import cx from "classnames";
import styles from "./styles.module.scss";

interface ITabs {
  items: string[];
  className?: string;
  currentTab: number;
  setCurrentTab: (tab: number) => void;
  type?: string;
}

const Tabs: FC<ITabs> = ({ items, className, currentTab, setCurrentTab, type }) => {
  return (
    <ul className={cx(styles.container, className)}>
      {items.map((item, index) => (
        <li
          className={cx(styles.item, {
            [styles.current]: index === currentTab,
            [styles.big]: type === "big",
          })}
          key={index}
          onClick={() => setCurrentTab(index)}
        >
          {item}
        </li>
      ))}
    </ul>
  );
};

export default Tabs;
