import { FC } from "react";
import { ImageData } from "../../../types/ui";
import { ClientSiteData } from "../../../types/site";

interface IBlockImage {
  className?: string;
  image: ImageData;
  siteData: ClientSiteData;
  alt: string;
  mobile: boolean;
  lazyLoading?: boolean;
}

const BlockImage: FC<IBlockImage> = ({ siteData, image, alt, mobile, lazyLoading, className }) => {
  const url = siteData?.baseUrl + (mobile ? (image?.mobileUrl ? image?.mobileUrl : image?.url) : image?.url);

  if (lazyLoading && !siteData.siteForAutoTests) {
    return <img className={className} src={url} alt={alt} loading="lazy" />;
  }

  return <img className={className} src={url} alt={alt} />;
};

export default BlockImage;
