"use client";

import dynamic from "next/dynamic";
import { FC } from "react";
import { IPageBlock } from "../../../types/ui";

const OrderPartsForm = dynamic(() => import("./order-parts-form"));

const OrderPartsFormLoader: FC<IPageBlock> = (props) => <OrderPartsForm {...props} />;

export default OrderPartsFormLoader;
