"use client";

import dynamic from "next/dynamic";
import { FC } from "react";
import { IPageBlock } from "../../../types/ui";

const ModelsBlockHorizontal = dynamic(() => import("."));

const ModelsBlockHorizontalLoader: FC<IPageBlock> = (props) => <ModelsBlockHorizontal {...props} />;

export default ModelsBlockHorizontalLoader;
