"use client";

import dynamic from "next/dynamic";
import { FC } from "react";
import { IPageBlock } from "../../../types/ui";

const ValueYourTradeForm = dynamic(() => import("./value-your-trade-form"));

const ValueYourTradeFormLoader: FC<IPageBlock> = (props) => <ValueYourTradeForm {...props} />;

export default ValueYourTradeFormLoader;
