"use client";

import dynamic from "next/dynamic";
import { FC } from "react";
import { IPageBlock } from "../../../types/ui";

const InitScreen = dynamic(() => import("./init-screen"));

const InitiScreenLoader: FC<IPageBlock> = (props) => <InitScreen {...props} />;

export default InitiScreenLoader;
