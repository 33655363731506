"use client";

import dynamic from "next/dynamic";
import { FC } from "react";
import { IPageBlock } from "../../../types/ui";

const CarFinderToolForm = dynamic(() => import("./car-finder-tool-form"));

const CarFinderToolFormLoader: FC<IPageBlock> = (props) => <CarFinderToolForm {...props} />;

export default CarFinderToolFormLoader;
