"use client";
import { useState, FC } from "react";
import styles from "./qa-item.module.scss";
import MinusIcon from "../../icons/minus-icon";
import PlusIcon from "../../icons/plus-icon";
import StringToHtmlTransformer from "../../common-components/string-to-html-transformer";

export interface IQAItem {
  question: string;
  answer: string;
}

const QAItem: FC<IQAItem> = ({ question, answer }) => {
  const [isOpened, setIsOpened] = useState(false);
  return (
    <div className={styles.container} onClick={() => setIsOpened(!isOpened)}>
      <div className={styles.header}>
        <span className={styles.question}>{question}</span>
        {isOpened ? <MinusIcon className={styles.minus} /> : <PlusIcon className={styles.plus} />}
      </div>
      {isOpened && (
        <div className={styles.answer}>
          <StringToHtmlTransformer layout={answer} />
        </div>
      )}
    </div>
  );
};

export default QAItem;
