"use client";
import { useEffect, useRef, useCallback, FC } from "react";
import { IPageBlock } from "../../../types/ui";
import styles from "./styles.module.scss";

interface IYoutubePlayer {
  width?: number;
  height?: number;
  videoId: string;
  stopAutoPlay?: boolean;
  hideControls?: boolean;
}

const API_ID = "youtube-api-script";
const RESOLUTION = 16 / 9;
const DEFAULT_YOUTUBE_WIDTH = 640;
const DEFAULT_YOUTUBE_HEIGHT = 360;
let isApiReady = false;
let onApiReadyCallbacks: (() => void)[] = [];

const onApiReady = () => {
  isApiReady = true;
  onApiReadyCallbacks.forEach((cb) => cb());
  onApiReadyCallbacks = [];
};

const YoutubePlayer: FC<IPageBlock> = ({ config }) => {
  const youtubePlayerConfig: IYoutubePlayer = config;
  const playerRef = useRef(null);
  const videoId = youtubePlayerConfig?.videoId || "";
  const width = youtubePlayerConfig?.width || DEFAULT_YOUTUBE_WIDTH;
  let height = youtubePlayerConfig?.height
    ? youtubePlayerConfig?.height
    : youtubePlayerConfig?.width
      ? youtubePlayerConfig?.width / RESOLUTION
      : DEFAULT_YOUTUBE_HEIGHT;

  const createPlayer = useCallback(() => {
    new window.YT.Player(playerRef.current, {
      videoId,
      width,
      height,
      playerVars: {
        mute: 1,
        autoplay: youtubePlayerConfig?.stopAutoPlay ? 0 : 1,
        controls: youtubePlayerConfig?.hideControls ? 0 : 1,
        origin: location.protocol + "//" + location.host,
      },
    });
  }, [height, videoId, width, youtubePlayerConfig?.hideControls, youtubePlayerConfig?.stopAutoPlay]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.onYouTubeIframeAPIReady = onApiReady;
    }

    if (isApiReady) {
      createPlayer();
    } else {
      onApiReadyCallbacks.push(createPlayer);
      if (!document.getElementById(API_ID)) {
        const tag = document.createElement("script");
        tag.id = API_ID;
        tag.src = "https://www.youtube.com/iframe_api";
        const firstScriptTag = document.getElementsByTagName("script")[0];
        firstScriptTag?.parentNode?.insertBefore(tag, firstScriptTag);
      }
    }
  }, [createPlayer]);

  return (
    <div className={styles.container}>
      <div ref={playerRef} />
    </div>
  );
};

export default YoutubePlayer;
