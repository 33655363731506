"use client";

import { FC, useCallback } from "react";
import cx from "classnames";
import { IDealerInfoCard } from "../../../types/ui";
import styles from "./dealer-card.module.scss";
import LocationIcon from "../../icons/location-icon";
import PhoneIcon from "../../icons/phone-icon";
import WorldIcon from "../../icons/world-icon";
import Button from "../../common-components/button";
import { formatAddress } from "../../../utils/stringFormatter";
import { handleButtonAction } from "../../../utils/ui";
import { formatPhone } from "../../../utils/stringFormatter";
import { getGoogleMapsAddressUrl } from "../../../utils/map";
import { getButtonActionResult, sendAnalyticsEvent } from "../../../utils/analitics";

const DealerCard: FC<IDealerInfoCard> = ({ image, name, description, address, phone, website, button, location }) => {
  const buttonClickHandler = useCallback(() => {
    handleButtonAction(button);
    sendAnalyticsEvent("asc_cta_interaction", {
      element_text: button.title,
      element_title: button.title,
      element_type: "body",
      event_action_result: getButtonActionResult(button),
      link_url: button.path,
    });
  }, [button]);

  const onPhoneClick = useCallback(() => {
    sendAnalyticsEvent("asc_click_to_call", {
      comm_phone_number: phone,
      department: "sales",
    });
  }, [phone]);

  return (
    <div className={styles.container}>
      <div className={styles.image}>
        {image &&
          (website ? (
            <a
              title={"service"}
              href={website}
              className={cx(styles.link, styles.imageLink)}
              target="_blank"
              rel="noreferrer noopener"
            >
              <img src={image} alt={"service"} />
            </a>
          ) : (
            <img src={image} alt={"service"} />
          ))}
      </div>
      <div className={styles.content}>
        {name &&
          (website ? (
            <a
              title={name}
              href={website}
              className={cx(styles.link, styles.linkTitle)}
              target="_blank"
              rel="noreferrer noopener"
            >
              <h4 title={name} className={styles.title}>
                {name}
              </h4>
            </a>
          ) : (
            <h4 title={name} className={styles.title}>
              {name}
            </h4>
          ))}
        {description &&
          (website ? (
            <a title={description} href={website} className={styles.link} target="_blank" rel="noreferrer noopener">
              <p className={styles.description}>{description}</p>
            </a>
          ) : (
            <p className={styles.description}>{description}</p>
          ))}
        {address &&
          (location?.longitude && location?.latitide ? (
            <a
              title={"location"}
              href={getGoogleMapsAddressUrl(location)}
              className={cx(styles.link, styles.itemWithIcon)}
              target="_blank"
              rel="noreferrer noopener"
            >
              <LocationIcon />
              <span className={styles.text}>{formatAddress(address)}</span>
            </a>
          ) : (
            <div className={styles.itemWithIcon}>
              <LocationIcon />
              <span className={styles.text}>{formatAddress(address)}</span>
            </div>
          ))}
        {phone && (
          <a
            title={formatPhone(phone)}
            href={`tel:${phone}`}
            className={cx(styles.link, styles.itemWithIcon)}
            target="_blank"
            rel="noreferrer noopener"
            onClick={onPhoneClick}
          >
            <PhoneIcon />
            <span className={styles.text}>{formatPhone(phone)}</span>
          </a>
        )}
        {website && (
          <a
            title={website}
            href={website}
            className={cx(styles.link, styles.itemWithIcon)}
            target="_blank"
            rel="noreferrer noopener"
          >
            <WorldIcon />
            <span className={styles.text}>{website}</span>
          </a>
        )}
        {button && (
          <Button
            name={button.title}
            className={styles.button}
            type={"primary"}
            text={button.title}
            onClick={buttonClickHandler}
            ariaLabel={button.title}
          />
        )}
      </div>
    </div>
  );
};

export default DealerCard;
