"use client";
import styles from "./styles.module.scss";
import CMSEditIcon from "../../icons/cms-edit-icon";
import { FC, useEffect } from "react";

interface CMSEditButtonInterface {
  editPath: string;
}

const CMSEditButton: FC<CMSEditButtonInterface> = ({ editPath }) => {
  useEffect(() => {
    const url = new URL(window.location.href);
    window.name = `$AutomotiveX@${url.host}`;
  }, []);
  return (
    <button
      aria-label={"cms edit button"}
      name={"CMSEditButton"}
      className={styles.button}
      onClick={() => {
        const url = new URL(editPath);
        window.open(editPath, `$AutomotiveX@${url.host}`);
      }}
    >
      <CMSEditIcon />
    </button>
  );
};

export default CMSEditButton;
