"use client";
import { FC } from "react";
import cx from "classnames";
import styles from "./styles.module.scss";
import { useIsMobile } from "../../../utils/useIsMobile";
import { InfoBlockType, IPageBlock } from "../../../types/ui";
import BlockImage from "../../common-components/block-image";
import StringToHtmlTransformer from "../../common-components/string-to-html-transformer";

interface IInfoBlockWide extends IPageBlock {
  className?: string;
}

const InfoBlockWide: FC<IInfoBlockWide> = ({ config, className, siteData, pageContext }) => {
  const isDesktop = useIsMobile(1920, !pageContext.isSmartphone);
  const isMediumDesktop = useIsMobile(1760, !pageContext.isSmartphone);

  return (
    <section className={cx(styles.container, className)} data-type="info-block">
      <div
        className={cx(styles.background, {
          [styles.imageRightSide]:
            config.type === InfoBlockType.RIGHT ||
            !config.type ||
            (!isMediumDesktop && config.type === InfoBlockType.TOP),
          [styles.imageLeftSide]:
            config.type === InfoBlockType.LEFT || (!isMediumDesktop && config.type === InfoBlockType.BOTTOM),
          [styles.imageTopSide]: isDesktop && config.type === InfoBlockType.TOP,
          [styles.imageBottomSide]: isDesktop && config.type === InfoBlockType.BOTTOM,
          [styles.noImage]: config?.image?.url === ("" || undefined),
          [styles.grey]: config?.background === "grey",
          [styles.white]: config?.background === "white" || !config?.background,
        })}
      >
        {config?.image?.url && (
          <BlockImage
            className={styles.image}
            image={config.image}
            lazyLoading={config.lazyLoading}
            mobile={pageContext.isSmartphone}
            siteData={siteData}
            alt={config.title || ""}
          />
        )}
        <div className={styles.content}>
          <h3 title={config.title} className={styles.title}>
            {config.title}
          </h3>
          <div className={styles.info}>
            {config.text && (
              <div className={styles.text}>
                <StringToHtmlTransformer layout={config.text} />
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default InfoBlockWide;
