"use client";

import cx from "classnames";
import { FC, useCallback } from "react";
import styles from "./styles.module.scss";
import { ImageData, IPageBlock } from "../../../types/ui";
import BlockImage from "../../common-components/block-image";
import Button from "@/ui/common-components/button";
import { useIsMobile } from "../../../utils/useIsMobile";
import { ButtonAction } from "../../../types/site";
import StringToHtmlTransformer from "../../common-components/string-to-html-transformer";
import { sendAnalyticsEvent } from "@/utils/analitics";
import { handleButtonAction } from "@/utils/ui";

interface ServiceItemData {
  title: string;
  text: string;
  image: ImageData;
  button: ButtonAction;
}

interface ServiceData {
  items: ServiceItemData[];
}

const HomeService: FC<IPageBlock> = ({ config, pageContext, siteData }) => {
  const isMobile = useIsMobile(768, pageContext.isSmartphone);
  const serviceInfoData: ServiceData = config;
  const items = serviceInfoData?.items || [];

  const handleButtonClick = useCallback((button: ButtonAction) => {
    sendAnalyticsEvent("asc_cta_interaction", {
      element_text: button.title,
      element_type: "body",
      element_title: button.title,
      event_action_result: "open",
      link_url: button.path,
    });
    handleButtonAction(button);
  }, []);

  return serviceInfoData ? (
    <section
      className={styles.container}
      style={{ gridTemplateColumns: `repeat(${(isMobile ? 1 : items.length) || 1}, 1fr)` }}
    >
      {items.map((item, index) => (
        <div className={styles.itemContainer} key={index}>
          <div
            className={cx(styles.item, {
              [styles.itemSingle]: items.length === 1,
            })}
          >
            {item.image && (
              <BlockImage
                image={item.image}
                alt={item.title}
                className={styles.icon}
                lazyLoading={true}
                mobile={pageContext.isSmartphone}
                siteData={siteData}
              />
            )}
            <h4 title={item.title} className={styles.title}>
              {item.title}
            </h4>
            <div className={styles.text}>
              <StringToHtmlTransformer layout={item?.text || ""} />
            </div>
            {item.button && (
              <Button
                name={item?.button?.title}
                ariaLabel={item?.button?.title}
                className={styles.link}
                type={"secondary"}
                text={item?.button?.title}
                onClick={() => handleButtonClick(item?.button!)}
              />
            )}
          </div>
        </div>
      ))}
    </section>
  ) : null;
};

export default HomeService;
