"use client";
import { useRef, useEffect, useCallback, FC } from "react";
import styles from "./styles.module.scss";
import { ButtonAction } from "../../../types/site";
import { handleButtonAction } from "../../../utils/ui";
import Button from "../../common-components/button";
import { useIsMobile } from "../../../utils/useIsMobile";
import calculateBannerHeight from "../../../utils/calculateBannerHeight";
import { IPageBlock } from "../../../types/ui";
import { getButtonActionResult, sendAnalyticsEvent } from "../../../utils/analitics";

interface IVideoComponentConfig {
  url: string;
  title?: string;
  text?: string;
  button?: ButtonAction;
  height?: number;
}

const VideoComponent: FC<IPageBlock> = ({ siteData, config, pageContext }) => {
  const videoConfig: IVideoComponentConfig = config;
  const isMobile = useIsMobile(1024, pageContext.isSmartphone);
  const videoRef = useRef(null) as any;
  const itemsAvailable = videoConfig?.title || videoConfig?.text ? true : false;

  const videoUrl =
    videoConfig?.url && (videoConfig.url.startsWith("http://") || videoConfig.url.startsWith("https://"))
      ? videoConfig.url
      : `${siteData.baseUrl}${videoConfig.url}`;
  const shouldAutoPlay = !siteData.siteForAutoTests;
  const autoPlayAttribute = shouldAutoPlay ? "autoplay" : "";

  const onButtonClick = useCallback(() => {
    if (videoConfig?.button) {
      handleButtonAction(videoConfig?.button);
      sendAnalyticsEvent("asc_cta_interaction", {
        element_text: videoConfig.button.title,
        element_title: "video",
        element_type: "video",
        event_action_result: getButtonActionResult(videoConfig.button),
        link_url: videoConfig.button.path,
      });
    }
  }, [videoConfig.button]);

  useEffect(() => {
    if (videoRef.current) {
      const player = videoRef.current.children[0];

      if (player) {
        player.controls = false;
        player.playsinline = true;
        player.muted = true;
        player.setAttribute("muted", "");
        if (shouldAutoPlay) {
          window.addEventListener("load", () => {
            player.play();
          });
          player.autoplay = true;
          player.play();
        }
      }
    }
  }, [shouldAutoPlay]);

  return (
    <section
      className={styles.container}
      style={{
        maxHeight: calculateBannerHeight(isMobile, videoConfig?.height),
        minHeight: calculateBannerHeight(isMobile, videoConfig?.height),
      }}
    >
      <div
        ref={videoRef}
        dangerouslySetInnerHTML={{
          __html: `
        <video loop muted ${autoPlayAttribute} playsinline src="${videoUrl}" class="${styles.video}" />`,
        }}
      ></div>
      {itemsAvailable && (
        <div className={styles.content}>
          <div className={styles.contentContainer}>
            {videoConfig?.title && <h3 className={styles.title}>{videoConfig?.title}</h3>}
            {videoConfig?.text && <p className={styles.text}>{videoConfig?.text}</p>}
            {videoConfig?.button?.path && (
              <Button
                name={"videoComponentButton"}
                type={"primary"}
                text={videoConfig?.button?.title || ""}
                className={styles.button}
                onClick={onButtonClick}
                ariaLabel={videoConfig?.button?.title}
              />
            )}
          </div>
        </div>
      )}
    </section>
  );
};

export default VideoComponent;
