const CMSEditIcon = () => (
  <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.6464 2.20712C15.3131 1.54045 16.394 1.54044 17.0606 2.20712C17.7273 2.87379 17.7273 3.95468 17.0606 4.62135L6.47114 15.2108L0.938598 18.3292L4.05691 12.7966L14.6464 2.20712Z"
      fill="currentColor"
    />
    <path
      d="M6.47114 15.2108L17.0606 4.62135C17.7273 3.95468 17.7273 2.87379 17.0606 2.20712V2.20712C16.394 1.54044 15.3131 1.54045 14.6464 2.20712L4.05691 12.7966M6.47114 15.2108L0.938598 18.3292L4.05691 12.7966M6.47114 15.2108L4.05691 12.7966"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
  </svg>
);

export default CMSEditIcon;
