"use client";

import dynamic from "next/dynamic";
import { FC } from "react";
import { IPageBlock } from "../../../types/ui";

const EmploymentApplyForm = dynamic(() => import("./employment-apply-form"));

const EmploymentApplyFormLoader: FC<IPageBlock> = (props) => <EmploymentApplyForm {...props} />;

export default EmploymentApplyFormLoader;
