"use client";

import { useCallback, FC } from "react";
import styles from "./service-section.module.scss";
import ServiceCard from "./service-card";
import { ButtonAction } from "../../../types/site";
import { handleButtonAction } from "../../../utils/ui";
import { IPageBlock } from "../../../types/ui";
import Button from "../../common-components/button";
import { ServicesData } from "../../../types/service";
import { getButtonActionResult, sendAnalyticsEvent } from "../../../utils/analitics";

const ServiceSection: FC<IPageBlock> = ({ config, siteData, pageContext }) => {
  const handleSectionButton = useCallback((button: ButtonAction) => {
    handleButtonAction(button);
    sendAnalyticsEvent("asc_cta_interaction", {
      element_text: button.title,
      element_title: button.title,
      element_type: "body",
      event_action_result: getButtonActionResult(button),
      link_url: button.path,
    });
  }, []);
  const serviceConfig: ServicesData = config;

  return serviceConfig.items.length ? (
    <section className={styles.section}>
      <h4 className={styles.title}>{serviceConfig?.title}</h4>
      <div className={styles.sectionContainer}>
        {config?.items?.map((service: any, index: number) => (
          <ServiceCard
            key={index}
            image={service.image}
            title={service.title}
            text={service.text || ""}
            button={service.button}
            className={styles.card}
            isMobile={pageContext.isSmartphone}
            siteData={siteData}
          />
        ))}
      </div>
      {serviceConfig?.button && (
        <Button
          name={config?.button.title}
          ariaLabel={config?.button.title}
          type={"secondary"}
          text={config?.button.title}
          className={styles.sectionButton}
          onClick={() => handleSectionButton(serviceConfig?.button!)}
        />
      )}
    </section>
  ) : null;
};

export default ServiceSection;
