const calculateBannerHeight = (isMobile: boolean, height?: number | undefined): string => {
  if (height) {
    if (height < 40 || height > 100) {
      return `calc(100vh - ${isMobile ? 215 : 121}px)`;
    } else {
      return `${height}vh`;
    }
  } else {
    return `calc(100vh - ${isMobile ? 215 : 121}px)`;
  }
};

export default calculateBannerHeight;
