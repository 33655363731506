"use client";

import dynamic from "next/dynamic";
import { FC } from "react";
import { IPageBlock } from "../../../types/ui";

const MosaicBanner = dynamic(() => import("./mosaic-banner"));

const MosaicBannerLoader: FC<IPageBlock> = (props) => <MosaicBanner {...props} />;

export default MosaicBannerLoader;
